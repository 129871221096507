
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Basis'
})
export default class OndutyAnalysis extends Vue {
  radio = 'requisition'

  created () {
    // do something
    this.radio = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  handleChange (value: string) {
    this.$router.replace('/requisitionRefund/' + value)
  }
}
